<template>
  <b-container fluid>
    <div class="row mb-1">
      <div class="col-md-6">
        <div class="d-flex justify-content-left mt-1">
          <!-- <b-form-select text-field="placa.placa" value-field="id" style="width: 50%" v-model="placaSeleccionada">
            <template #first>
              <b-form-select-option v-for="it of listaServicios" :key="it.servicioId" :value="it">{{
                it.servicioId +
                " | " +
                it.placa.placa +
                " | " +
                $moment.tz(it.fecha, "America/Bogota").format(" DD-MM-YYYY HH:mm") +
                " | " +
                it.conductor.nombres +
                " " +
                it.conductor.apellidos
              }}</b-form-select-option>
            </template>
          </b-form-select> -->
         
          <b-form-select v-model="placaSeleccionada">
            <template #first>
              <b-form-select-option :value="null" disabled>-- seleccionar placa --</b-form-select-option>
              <b-form-select-option v-for="it of listaImei" :key="it.id" :value="it">{{ it.placaVehiculo }}</b-form-select-option>
            </template>
          </b-form-select>
          <input v-model="desde" type="datetime-local" class="form-control" required />
          <input v-model="hasta" type="datetime-local" class="form-control" required />

          <!-- <b-form-select v-model="servicioSeleccionado">
            <template #first>
              <b-form-select-option v-for="its of listaServicios" :key="its.id" :value="its">{{ its.fechaInicio + " / " + its.fechaFin }}</b-form-select-option>
            </template>
          </b-form-select> -->
        </div>
      </div>
      <div class="col-md-3">
        <div class="d-flex justify-content-left mt-1">
          <vs-button   primary @click.prevent="obtenerCoordenadasPlaca()">OBTENER DATOS</vs-button>
          <!-- <vs-button   primary @click.prevent="seleccionaPlaca()">INICIAR RUTA</vs-button> -->
        </div>
      </div>
      <!-- <div class="col-md-5 mb-1">
        <CardTable :data="dataCards" />
      </div> -->
    </div>
    <!-- <div class="row">
      <div class="col-3">
        <b-form-select v-model="placaSeleccionada">
          <template #first>
            <b-form-select-option v-for="its of listaServicios" :key="its.id" :value="its">{{ its.fechaInicio + " / " + its.fechaFin }}</b-form-select-option>
          </template>
        </b-form-select>
      </div>
    </div> -->

    <div v-if="coordinates.length > 0" class="row">
      <div class="col-md-3 text-center mt-1">
        <!-- <span class="">{{ coordinates.length + " Coordenadas" }}</span
        ><br /> -->
        <!-- <span class="">{{ (coordinates.map((objeto) => Number(objeto.velocidad)).reduce((total, valor) => total + valor, 0) / this.coordinates.length).toFixed(2) }}</span> -->
        <!-- {{ contadorProgreso }} -->
      </div>
    </div>

    <div class="row mt-0">
      <!-- <div v-if="coordinates.length > 0" class="col-md-4">
        <div class="mt-0" style="overflow-y: scroll; height: 400px">
          <table id="pendientesJefe" class="table table-bordered table-responsive-md table-striped text-center">
            <thead>
              <tr>
                <th>Fecha</th>
                <th>Latitud</th>
                <th>Longitud</th>
                <th>Velocidad</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in coordinates" :key="item.id">
                <td>{{ $moment.tz(item.fecha, "America/Costa_Rica").format("DD-MM-YYYY HH:mm") }}</td>
                <td>{{ item.lat }}</td>
                <td>{{ item.lng }}</td>
                <td>{{ item.velocidad }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> -->
      <div v-if="coordinates.length > 0" class="col-md-3">
        <div class="text-justify mt-2" style="overflow-y: overlay; height: 69vh !important">
          <div
            v-for="(item, index) in coordinates"
            :key="item.id"
            class="bg-dark mt-1 mb-1 p-3 pointer activated"
            style="border-radius: 5%"
            @click="agregarMarca(item)"
            tabindex="0"
          >
            <div class="row mb-1">
              <span class="col-md-5 col-sm-12">{{ "Nº " + (index + 1) }} </span><br />
            </div>
            <div class="row">
              <span class="col-md-5 col-sm-12">Fecha:</span>
              <span class="col-md-7 col-sm-12 text-green italic">{{ $moment.tz(item.fecha, "America/Costa_Rica").format("DD-MM-YYYY") }}</span>
            </div>
            <div class="row">
              <span class="col-md-5 col-sm-12">Hora:</span>
              <span class="col-md-7 col-sm-12 text-green italic">{{ $moment.tz(item.fecha, "America/Costa_Rica").format("HH:mm:ss") }}</span>
            </div>

            <div class="row">
              <span class="col-md-5 col-sm-12">Latitud: </span>
              <span class="col-md-7 col-sm-12 text-green italic">{{ item.lat }}</span>
            </div>
            <div class="row">
              <span class="col-md-5 col-sm-12">Longitud: </span>
              <span class="col-md-7 col-sm-12 text-green italic">{{ item.lng }}</span>
            </div>

            <div class="row">
              <span class="col-md-5 col-sm-12">Velocidad: </span>
              <span class="col-md-7 col-sm-12 italic" :class="item.velocidad == 0 ? '; color:gray;' : item.velocidad < 60 ? ';color:blue' : ';color:Red'"
                >{{ item.velocidad + " km/h" }}
              </span>
            </div>
            <div class="row">
              <span class="col-md-5 col-sm-12">Distancia: </span>
              <span class="col-md-7 col-sm-12 italic">{{ Number(item.distancia).toFixed(2) + " mts" }} </span>
            </div>
          </div>
        </div>
      </div>
      <div :class="coordinates.length > 0 ? 'col-md-9' : 'col-md-12'">
        <div class="statusbar mt-2">
          <div class="row text-center">
            <div class="col-md-6">
              <span><a class="fa fa-map-marker green" /> Distancia total aprox.: {{ distanciaTotal.toFixed(2) + " Km." }}</span>
            </div>
            <div class="col-md-6">
              <span> <a class="fa fa-tachometer green" /> Duración aprox.: {{ duracion }}</span>
            </div>
            <!-- <div class="col-md-3">
              <span><a class="fa fa-tachometer green" /> Velocidad prom. aprox.: {{ velocidadProm.toFixed(2) + " Km." }}</span>
            </div> -->
          </div>
          <div class="row text-center">
            <div class="col-md-6">
              <span><a class="fa fa-map-marker green" /> total coord.: {{ coordinates.length }}</span>
            </div>
            <!-- <div class="col-md-6">
              <span> <a class="fa fa-tachometer green" /> Duración aprox.: {{ duracion }}</span>
            </div> -->
            <!-- <div class="col-md-3">
              <span><a class="fa fa-tachometer green" /> Velocidad prom. aprox.: {{ velocidadProm.toFixed(2) + " Km." }}</span>
            </div> -->
          </div>
        </div>
        <!--<b-progress height="5px" :value="contadorProgreso" :max="coordinates.length" :label-html="`<del>${value}</del>`" show-progress animated> </b-progress>-->
        <l-map :options="mapOptions" style="height: 65vh !important; z-index: -99 !important;" :zoom="zoom" :center="center">
          <l-icon-default />
          <l-tile-layer :url="url" :attribution="attribution" />
          <l-marker v-for="(marker, index2) in markerLatLng" :key="index2" :lat-lng="marker" :icon="customIcon" ref="markerRef"> </l-marker>
          <l-marker :icon="customIconIni" :lat-lng="markerLatLngIni" ref="markerRefIni"></l-marker>
          <l-marker :icon="customIconFin" :lat-lng="markerLatLngFin" ref="markerRefFin"></l-marker>
          <l-polyline :lat-lngs="polylinePath" :options="polylineOptions"></l-polyline>
        </l-map>
      </div>
    </div>
  </b-container>
</template>

<script>
import { LMap, LTileLayer, LMarker, LIconDefault, LPolyline } from "vue2-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
// import CardTable from "../../../components/cardTable/CardTable.vue";
// import CardTable from "../../../components/cardTable/CardTableSmall.vue";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIconDefault,
    LPolyline,
    
    // CardTable,
  },
  data() {
    return {
      desde: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD HH:mm"),
      hasta: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD HH:mm"),
      velocidadProm: 0,
      servicioSeleccionado: null,
      duracion: 0,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 16,
      center: [4.7279077, -74.062912],
      markerLatLng: [],
      markerLatLngIni: [0, 0],
      markerLatLngFin: [0, 0],
      customIconIni: L.icon({}),
      customIconFin: L.icon({}),
      customIcon: L.icon({
        // iconUrl: require("@/assets/images/vehiculo1.png"),
        // iconSize: [50, 50],
        // iconAnchor: [16, 16],
        // popupAnchor: [10, -32],
      }),
      coordinates: [],
      polylinePath: [], // Para almacenar las coordenadas de la línea
      polylineOptions: {
        color: "red",
        weight: 6,
      },
      persona: {},
      empresaId: {},
      listaServicios: [{ id: 1, imei: "864035050968024", placa: "HFT052", fechaInicio: "2024-02-25 04:38:13", fechaFin: "2024-02-25 04:58:24" }],
      placaSeleccionada: null,
      listaImei: [
        // { id: 1, imei: "864035050968024" },
        // { id: 2, imei: "864035050967562" },
      ],
      dataCards: [
        {
          title: "Total Coordenadas",
          value: () => this.coordinates.length,
          iconName: "map-marker",
          color: "rgba(22, 162, 117, 1)",
          tipo: "squared",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
          },
          onDeactivate: () => {
            console.log("Deactivate");
          },
        },
        {
          title: "Distancia total",
          value: () => this.distanciaTotal.toFixed(2) + " Km",
          iconName: "tachometer",
          color: "rgba(223, 18, 46, 1)",
          tipo: "shield",
          animated: "bounce",
          // onActivate: () => {
          //   console.log("Activated");
          //   this.dataTableInstance.search("Alta").draw();
          // },
          // onDeactivate: () => {
          //   this.dataTableInstance.search("").draw();
          //   console.log("Desactivate");
          // },
        },
        // {
        //   title: "Prioridad Media",
        //   value: () => this.listaItems.filter((tick) => tick.prioridad == 2).length,
        //   iconName: "bell",
        //   color: "rgba(254, 114, 28, 1)",
        //   tipo: "shield",
        //   animated: "bounce",
        //   onActivate: () => {
        //     this.dataTableInstance.search("media").draw();
        //     console.log("Activated");
        //   },
        //   onDeactivate: () => {
        //     this.dataTableInstance.search("").draw();
        //     console.log("Deactivate");
        //   },
        // },
        // {
        //   title: "Prioridad baja",
        //   value: () => this.listaItems.filter((tick) => tick.prioridad == 1).length,
        //   iconName: "hand-point-up",
        //   color: "rgba(71, 136, 255, 1)",
        //   tipo: "shield",
        //   animated: "bounce",
        //   onActivate: () => {
        //     this.dataTableInstance.search("Baja").draw();
        //     console.log("Activated");
        //   },
        //   onDeactivate: () => {
        //     this.dataTableInstance.search("").draw();
        //     console.log("Deactivate");
        //   },
        // },
      ],
      contadorProgreso: 0,
      distanciaTotal: 0,
      markers: [],
      popupOptions: {
        autoClose: false, // Para mantener el popup abierto
      },
      popupMaxWidth: "300px",
      mapOptions: {
        autoClosePopup: false, // Evitar que el mapa cierre automáticamente los popups
      },
    };
  },
  async mounted() {
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.empresaId = this.persona.empresa.id;
    await this.getListByEmpresa();
  },
  methods: {
    agregarMarca(itemMarca) {
      console.log(itemMarca);
      this.customIcon = L.icon({
        iconUrl: require("@/assets/images/marca1.png"),
        iconSize: [50, 50],
        iconAnchor: [16, 16],
        popupAnchor: [10, -32],
      });
      this.markerLatLng.push([itemMarca.lat, itemMarca.lng]);
      this.center = [itemMarca.lat, itemMarca.lng];
      this.$forceUpdate();

      // this.markers = [{ position: [itemMarca.lat, itemMarca.lng], popupContent: "Popup 1" }];
      // this.markerLatLng.forEach((marker, index) => {
      console.log(this.$refs.markerRef);
      setTimeout(() => {
        const leafletMarker = this.$refs.markerRef[this.markerLatLng.length - 1].mapObject;
        leafletMarker
          .bindPopup(
            `
            <div class="row">
              <span class="col-md-12 col-sm-12 text-green italic">${this.$moment.tz(itemMarca.fecha, "America/Costa_Rica").format("DD-MM-YYYY HH:mm:ss")}</span>
            </div>
            <div class="row">
              <span class="col-md-12 col-sm-12 text-green italic">${itemMarca.lat + ", " + itemMarca.lng}</span>
            </div>

            <div class="row">
              <span class="col-md-12 col-sm-12 text-primary">Velocidad: ${itemMarca.velocidad} km/h</span>
            </div>
            <div class="row">
              <span class="col-md-12 col-sm-12 text-primary">Distancia: ${Number(itemMarca.distancia).toFixed(2)} mts} </span>
            </div>

        `,
            { autoClose: false }
          )
          .openPopup();
        const popup = leafletMarker.getPopup();
        if (popup) {
          popup.$content.style.maxWidth = this.popupMaxWidth;
        }
      }, 500);
      // });

      // console.log("marker ----------------->", marker);
      // marker.bindPopup("123", { autoClose: false }).openPopup();

      // marker.setPopupContent("lat:" + lat + " / lng:" + lng + " /" + this.$moment.tz(this.coordinates[i].fecha, "America/Bogota").format(" DD-MM-YYYY HH:mm:ss"));
      // marker.setPopupContent("lat: asdasdasd");
      //     this.polylinePath.push([lat, lng]);
    },
    sumarTiempos(fechas) {
      // Inicializar la suma total de milisegundos
      var sumaTotal = 0;

      // Calcular la diferencia entre cada fecha y sumarla
      for (var i = 0; i < fechas.length - 1; i++) {
        var diferencia = new Date(fechas[i + 1]) - new Date(fechas[i]); // Diferencia en milisegundos
        sumaTotal += diferencia;
      }

      // Calcular los días, horas, minutos y segundos a partir de la suma total
      var segundosTotal = Math.floor(sumaTotal / 1000);
      var minutosTotal = Math.floor(segundosTotal / 60);
      var horasTotal = Math.floor(minutosTotal / 60);
      var dias = Math.floor(horasTotal / 24);

      // Calcular las horas, minutos y segundos restantes después de convertir días
      var horas = horasTotal % 24;
      var minutos = minutosTotal % 60;
      // var segundos = segundosTotal % 60;

      // Construir la cadena de resultado
      // var resultado = (dias > 0 ? dias + " d.," : "") + horas + " h., " + minutos + " min., " + segundos + " segs.";
      var resultado = (dias > 0 ? dias + " d.," : "") + horas + " h., " + minutos + " min.";

      return resultado;
    },

    async getListByEmpresa() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("hl_get", {
          path: "VehiculoRegistroImeiGPS/ListByEmpresa/" + this.empresaId,
        });
        this.$isLoading(false);
        console.log("imei", res);
        if (res.data.length > 0) {
          this.listaImei = res.data;
        } else {
          this.listaImei = [];
        }
      } catch (error) {
        this.listaImei = [];
      }
    },
    calcularDistanciaTotal() {
      let distanciaTotal = 0;
      let coordenadas = this.coordinates.map((x) => {
        let data = [x.lat, x.lng];
        return data;
      });
      for (let i = 1; i < coordenadas.length; i++) {
        const puntoActual = coordenadas[i];
        const puntoAnterior = coordenadas[i - 1];

        const distanciaEntrePuntos = this.distanciaHaversine(puntoAnterior[0], puntoAnterior[1], puntoActual[0], puntoActual[1]);
        distanciaTotal += distanciaEntrePuntos;
      }

      return distanciaTotal;
    },

    distanciaHaversine(lat1, lon1, lat2, lon2) {
      const radioTierra = 6371; // Radio de la Tierra en kilómetros
      const dLat = this.gradosARadianes(lat2 - lat1);
      const dLon = this.gradosARadianes(lon2 - lon1);
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.gradosARadianes(lat1)) * Math.cos(this.gradosARadianes(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distancia = radioTierra * c;

      return distancia;
    },

    gradosARadianes(grados) {
      return grados * (Math.PI / 180);
    },

    showToast(type, message) {
      console.log(type);
      if (type == "success") {
        this.$toast.success(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
      if (type == "error")
        this.$toast.error(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      if (type == "warning")
        this.$toast.warning(message, {
          position: "top-center",
          timeout: 1492,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.41,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
    },
    calcularDistanciaEntreCoordenadas(coordenadas1, coordenadas2) {
      const radioTierraKm = 6371; // Radio de la Tierra en kilómetros

      const [lat1, lon1] = coordenadas1;
      const [lat2, lon2] = coordenadas2;

      const dLat = ((lat2 - lat1) * Math.PI) / 180;
      const dLon = ((lon2 - lon1) * Math.PI) / 180;

      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distancia = radioTierraKm * c;

      return distancia;
    },
    //limpiezaFase1
    async eliminaCeroCero(res) {
      let dataAnterior = null;
      let dataActual = {};
      let resTmp = [];
      for await (let itm of res) {
        dataActual = itm;
        itm.fecha = this.$moment(itm.fecha).subtract(this.placaSeleccionada.ajusteHora, "hours");
        //itm.fecha = itm.fecha.format("YYYY-MM-DD HH:mm:ss");

        if (dataAnterior == null) {
          console.log("se agrega por dataanterior null");
          //this.coordinates.push(itm);
          resTmp.push(itm);
        } else {
          if (dataActual.velocidad == 0 && dataAnterior.velocidad == 0) {
            console.log("se ignora porque hay mas de un valor en velocidad 0");
          } else {
            console.log("se agrega por condicion else");
            resTmp.push(itm);
          }
        }
        dataAnterior = itm;
      }
      return resTmp;
    },
    //limpiezaFase2
    async eliminaCeroUnoCero(resTmp) {
      let contadorTope = 1;
      let dataAnterior = null;
      let dataAnterior2 = null;
      let dataActual = {};
      let resTmp2 = [];
      for await (let itm of resTmp) {
        dataActual = itm;
        if (dataAnterior == null) {
          console.log("se agrega por dataanterior null");
          resTmp2.push(itm);
        } else {
          if (contadorTope == 1) {
            dataAnterior2 = itm;
          }
          if (contadorTope == 2) {
            dataAnterior = itm;
          }
          if (contadorTope == 3) {
            dataActual = itm;
            contadorTope = 0;
          }

          if (dataAnterior2.velocidad == 0 && dataAnterior.velocidad > 0 && dataActual.velocidad == 0) {
            console.log("se ignora porque hay mas de un valor en velocidad 0");
          } else {
            console.log("se agrega por condicion else");
            resTmp2.push("res final ", itm);
          }
        }
        contadorTope + 1;
      }
      return resTmp2;
    },
    //limpiezaFase3
    async eliminaVelocidadMinima(resTmp, velocidad) {
      let datafinal = resTmp.filter((x) => x.velocidad > velocidad);
      return datafinal;
    },
    async eliminaVelocidadMaxima(resTmp, velocidad) {
      let datafinal = resTmp.filter((x) => x.distancia < velocidad);
      return datafinal;
    },
    //limpiezaFase4
    async faseCalculaDistanciaEntreCoordenadas(resTmp) {
      let dataTmp = resTmp.map((coordenada) => {
        return [Number(coordenada.lat), Number(coordenada.lng)]; // [latitud, longitud]
      });

      //let resTmp5 = await this.limpiezaFase4(resTmp4);
      for (let i = 0; i < dataTmp.length; i++) {
        for (let j = i + 1; j < dataTmp.length; j++) {
          const distancia = this.calcularDistanciaEntreCoordenadas(dataTmp[i], dataTmp[j]);
          resTmp[j].distancia = distancia * 1000;
          // console.log(`Distancia entre coordenadas ${i} y ${j}: ${distancia.toFixed(2)} kilómetros`);
        }
      }
      resTmp = resTmp.filter((x) => x.distancia > 0);
      return resTmp;
    },
    //limpiezaFase5

    async FaseCeroUnoUnoCero(resTmp) {
      let contadorTope = 1;
      let dataAnterior = null;
      let dataAnterior2 = null;
      let dataAnterior3 = null;
      let dataActual = {};
      let resTmp2 = [];
      for await (let itm of resTmp) {
        dataActual = itm;
        if (dataAnterior == null) {
          console.log("se agrega por dataanterior null");
          resTmp2.push(itm);
        } else {
          if (contadorTope == 1) {
            dataAnterior3 = itm;
          }
          if (contadorTope == 2) {
            dataAnterior2 = itm;
          }
          if (contadorTope == 3) {
            dataAnterior = itm;
          }
          if (contadorTope == 4) {
            dataActual = itm;
            contadorTope = 0;
          }
          if (dataAnterior3.velocidad == 0 && dataAnterior2.velocidad > 0 && dataAnterior.velocidad > 0 && dataActual.velocidad == 0) {
            console.log("se ignora porque hay mas de un valor en velocidad 0");
          } else {
            console.log("se agrega por condicion else");
            resTmp2.push("res final ", itm);
          }
        }
        contadorTope + 1;
      }
      return resTmp2;
    },
    async obtenerCoordenadasPlaca() {
      try {
        this.coordinates = [];
        this.polylinePath = [];
        this.markerLatLng = [];
        this.markerLatLngIni = [0, 0];
        this.markerLatLngFin = [0, 0];
        this.polylinePath = [];
        console.log("placa seleccionada", this.placaSeleccionada);
        this.$isLoading(true);
        // let res = await this.$store.dispatch("hl_get", {
        //   path: "Vehiculo/CoordenadasPorImei/" + this.placaSeleccionada,
        // });
        let res = await this.$store.dispatch("hl_get", {
          path: "Vehiculo/CoordenadasPorImeiDesdeHasta/" + this.placaSeleccionada.imei + "/" + this.desde + "/" + this.hasta,
        });

        this.$isLoading(false);
        console.log("res-->", res);
        if (res.length > 0) {
          // //eliminando grupo de coordenadas con velocidad cero y continuas
          // let resTmp = await this.limpiezaFase1(res);
          // //detectando patron en coord 0 1 0 1...
          // let resTmp2 = await this.limpiezaFase2(resTmp);
          // //detectando patron en coord 0 1 0 1...
          // let resTmp3 = await this.limpiezaFase2(resTmp2);
          // //eliminando velocidad 0
          // let resTmp4 = await this.limpiezaFase3(resTmp3);
          // //calculando distancia entre coordenadas
          // let resTmp5 = await this.limpiezaFase4(resTmp4);

          // //bloque funcional 06-03-2024
          // // //eliminando grupo de coordenadas con velocidad cero y continuas
          // let resTmp = await this.limpiezaFase1(res);
          // // //detectando patron en coord 0 1 0 1...
          // let resTmp2 = await this.limpiezaFase2(resTmp);
          // // //detectando patron en coord 0 1 0 1...
          // // let resTmp3 = await this.limpiezaFase2(resTmp2);
          // // //calculando distancia entre coordenadas
          // let resTmp5 = await this.limpiezaFase4(resTmp2);
          // let resTmp4 = await this.limpiezaFase5(resTmp5);
          // // //eliminando velocidad 0
          // resTmp4 = await this.limpiezaFase3(resTmp5);
          // // // probando depuración nueva
          // resTmp4 = resTmp4.filter((x) => x.velocidad > 5);

          // // //eliminando grupo de coordenadas con velocidad cero y continuas

          let resTmp0 = res; // await this.FaseCeroUnoUnoCero(res);
          // resTmp0 = await this.eliminaCeroCero(resTmp0);
          // resTmp0 = await this.eliminaCeroUnoCero(resTmp0);
          resTmp0 = await this.faseCalculaDistanciaEntreCoordenadas(resTmp0);
          // resTmp0 = await this.eliminaVelocidadMinima(resTmp0, 10);
          resTmp0 = await this.eliminaVelocidadMaxima(resTmp0, 400);

          // // //detectando patron en coord 0 1 0 1...
          //let resTmp2 = await this.limpiezaFase2(resTmp);
          // // //detectando patron en coord 0 1 0 1...
          // // let resTmp3 = await this.limpiezaFase2(resTmp2);
          // let resTmp5 = await this.limpiezaFase4(resTmp2);
          // // //eliminando velocidad 0
          // resTmp4 = await this.limpiezaFase3(resTmp5);
          // // // probando depuración nueva
          // resTmp4 = resTmp4.filter((x) => x.velocidad > 5);

          // this.coordinates = resTmp4;
          this.coordinates = resTmp0;

          this.polylinePath = this.coordinates.map((coordenada) => {
            return [Number(coordenada.lat), Number(coordenada.lng)]; // [latitud, longitud]
          });
          console.log("coordenadas final final ", this.coordinates);
          this.$forceUpdate();
          this.customIconIni = L.icon({
            iconUrl: require("@/assets/images/puntoa.png"),
            iconSize: [50, 50],
            iconAnchor: [16, 16],
            popupAnchor: [10, -32],
          });
          this.center = [this.coordinates[0].lat, this.coordinates[0].lng];
          this.markerLatLngIni = [this.coordinates[0].lat, this.coordinates[0].lng];
          this.customIconFin = L.icon({
            iconUrl: require("@/assets/images/puntob.png"),
            iconSize: [50, 50],
            iconAnchor: [16, 16],
            popupAnchor: [10, -32],
          });
          this.markerLatLngFin = [this.coordinates[this.coordinates.length - 1].lat, this.coordinates[this.coordinates.length - 1].lng];
          // console.log("polylines ", this.polylinePath);
          this.distanciaTotal = this.calcularDistanciaTotal();
          let fechas = this.coordinates.map((coordenada) => {
            return coordenada.fecha;
          });
          // console.log("fecha ->", fechas);
          this.duracion = this.sumarTiempos(fechas);
        } else {
          this.coordinates = [];
        }
      } catch (error) {
        this.coordinates = [];
        this.$isLoading(false);
        console.log("err", error);
      }
    },
    async seleccionaPlaca() {
      if (this.coordinates.length > 0) {
        console.log(this.placaSeleccionada);
        // await this.obtenerCoordenadasPlaca();
        if (this.coordinates.length > 0) {
          this.center = [this.coordinates[0].lat, this.coordinates[0].lng];
          this.markerLatLng = [this.coordinates[0].lat, this.coordinates[0].lng];
          this.iniciar();
        } else {
          this.showToast("error", "no hay datos.");
        }
      }
    },
    async getServicios() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "Vehiculo/ServiciosPorEmpresa/" + this.empresaId,
        });
        console.log(res);
        if (res.length > 0) {
          this.listaServicios = res;
        } else {
          this.listaServicios = [];
        }
      } catch (error) {
        this.listaServicios = [];
        console.log("err", error);
      }
    },
    iniciar() {
      this.zoom = 16;
      this.contadorProgreso = 0;
      this.$nextTick(() => {
        // this.addPopup();

        this.moveMarker();
      });
    },
    addPopup() {
      const marker = this.$refs.markerRef.mapObject;

      if (!marker) {
        console.error("No se pudo obtener la referencia del marcador.");
        return;
      }

      marker.bindPopup("123", { autoClose: false }).openPopup();
    },
    async moveMarker() {
      const marker = this.$refs.markerRef.mapObject;

      if (!marker) {
        console.error("No se pudo obtener la referencia del marcador.");
        return;
      }

      const duration = 2000; // Duración de la animación en milisegundos

      for (let i = 0; i < this.coordinates.length; i++) {
        this.contadorProgreso++;
        const startLatLng = marker.getLatLng();
        const targetLatLng = L.latLng(this.coordinates[i]);

        const startTime = new Date().getTime();

        while (new Date().getTime() - startTime <= duration) {
          const progress = (new Date().getTime() - startTime) / duration;
          const lat = startLatLng.lat + progress * (targetLatLng.lat - startLatLng.lat);
          const lng = startLatLng.lng + progress * (targetLatLng.lng - startLatLng.lng);

          marker.setLatLng([lat, lng]);
          this.center = [lat, lng];

          marker.setPopupContent("lat:" + lat + " / lng:" + lng + " /" + this.$moment.tz(this.coordinates[i].fecha, "America/Bogota").format(" DD-MM-YYYY HH:mm:ss"));
          this.polylinePath.push([lat, lng]);

          await this.sleep(16); // Esperar 16 milisegundos (aproximadamente 60 FPS)
        }
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style scoped>
.italic {
  font-style: italic !important;
}
.statusbar {
  background-color: rgb(62, 62, 62);
  height: 35px;
  font-size: 15px;
}
.green {
  color: rgb(12, 202, 126);
}
.activated {
  /* border: 2px solid black; */
}
.activated:focus {
  /* box-shadow: 0 0 0 2px white, 0 0 0 4px blue; */
  border: 2px solid white;
}
.activated:active {
  /* box-shadow: 0 0 0 2px white, 0 0 0 4px blue; */
}
/* .activated ::before {
  border: 2px solid white;
} */
</style>
